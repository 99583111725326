import React from 'react';

import { Section } from 'components/Section';

import style from './style.module.scss';

import { BorderedBox } from 'components/BorderedBox';

export function PagePicTitle({children, imageSrc, imageCmp, imageAlt}){
    let imgCmpl = null;
    if (imageSrc){
        imgCmpl = <img id={style.titlePicture} src={imageSrc} alt={imageAlt} />
    }else if (imageCmp){
        const SVGImg = imageCmp;
        imgCmpl = <SVGImg id={style.titlePicture} alt={imageAlt} />
    }

    return (
        <Section id={style.container}>
            <BorderedBox>
                <div id={style.titleCont}>
                    {children}
                </div>
            </BorderedBox>
            {imgCmpl}
        </Section>
    );
}