import React from 'react';
import { graphql } from "gatsby";
import Fade from 'react-reveal/Fade';

import PageWrap from 'components/PageWrap';
import { Section } from 'components/Section';
import { PagePicTitle } from 'components/PagePicTitle';

import './addons.scss';

import { FeaturePoint } from 'components/FeaturePoint';
import CallsToAction from 'components/CallsToAction';
import { Separator } from 'components/Separator';
import { SEO } from 'components/Helmets';

import AddOnsIcons from 'media/shared/modIcons/AddOns.inline.svg';

export default function AddOnsPage({data}){
    const CTATypes = ['SignUp', 'LearnMore'];
    const pagePath = "/addons";

    const previewImg = {
        url: data.file.childImageSharp.fixed.src,
        height: data.file.childImageSharp.fixed.height,
        width: data.file.childImageSharp.fixed.width,
        alt: "Add-ons icon"
    }

    return (
        <PageWrap noSEO={true} path={pagePath}>
            <SEO
                title="Add-ons - Mashoom"
                description="Add-ons offer a set of general features that apply across your account."
                previewImg={previewImg}
                path={pagePath}
            />
            <PagePicTitle
                imageCmp={AddOnsIcons}
                imageAlt="Add-ons icon">
                        <h1>Add-ons</h1>
                        <p>Add-ons offer a set of general features that apply across your account.</p>
            </PagePicTitle>
            <Fade left>
                <FeaturePoint
                    title="Custom styling"
                    moduleName="CustomStyle"
                    chargeKey="CustomIdent"
                    pricePara="Customize the look of your account for %X%"
                >
                    <p>Customized styling can be applied, so your corporate logo and colours on display when anyone is using your account.</p>
                    <p className="paraSpaceTop">The logo, colours and feel can all be changed, only a small label shown in the bottom right displays "powered by Mashoom". These changes also apply to the login screen when a unique link is used, providing complete coverage.</p>
                    <p className="paraSpaceTop">All automated emails, including file sharing, notification and invitation emails sent from your account will include your branding as well, providing end-to-end branding coverage.</p>
                </FeaturePoint>
                <Separator />
            </Fade>
                <Fade left>
                <FeaturePoint
                    title="Virus scanning"
                    moduleName="VirusScan"
                    chargeKey="FilesScanned"
                    pricePara="Ensure your aren't a victim of cyber crime for %X%"
                >
                    <p>When sending and receiving files you want to be confident that you are not passing on or receiving any viruses, it's a vital part of any cyber security strategy.</p>
                    <p className="paraSpaceTop">Our virus scanning add-on scans any files that are uploaded, via whatever means. This blanket coverage means you can be completely confident that whatever is on Mashoom, it's safe.</p>
                </FeaturePoint>
                <Separator />
            </Fade>
            <Fade left>
                <FeaturePoint
                    title="Reporting"
                    moduleName="Reporting"
                    chargeKey="FREE"
                    pricePara="We are proud of making your data work for you; this add-on is free"
                >
                    <p>Each module comes with many different reports that we are constantly adding to. No need to customize them yourself, they work straight out the box, in real time.</p>
                    <p className="paraSpaceTop">We also provide a general set of reports covering general usage, user statuses, security overviews and more.</p>
                </FeaturePoint>
                <Separator />
            </Fade>
            <Section id="FeatureCTA">
                <div id="FeatureCTACont">
                    <h2>Like what your hear?</h2>
                    <CallsToAction types={CTATypes}></CallsToAction>
                </div>
            </Section>
        </PageWrap>
    );
}

//eslint-disable-next-line no-undef
export const query = graphql`
    query AddonsPreviewImg {
        file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "shared/modIcons/AddOns.png"}) {
            childImageSharp {
                fixed(width: 800, height: 400, fit: CONTAIN, background: "white") {
                    src
                    height
                    width
                }
            }
        }
    }
`;