import React from 'react';

import Utils from 'library/Utils';

import { Section } from 'components/Section';

import style from './style.module.scss';

import { ModuleIcon } from 'components/ModuleIcon';
import PriceLabel from 'components/PriceLabel';
import IndyLink from 'components/IndyLink';

export function FeaturePoint({title, moduleName, image, pricePara, chargeKey, moreInfo, children, pageInd}){
    let priceParaCmpl = false;
    let className;

    if (pricePara){
        if (moreInfo){
            throw new Error('Either price or more info can be set, not both');
        }

        if (chargeKey !== "FREE"){
            const split = pricePara.split("%X%");

            if (split.length !== 2){
                throw new Error('Unrecognized price para string');
            }

            priceParaCmpl = [split[0], <PriceLabel chargeKey={chargeKey} />, split[1]];

        }else{
            const split = pricePara.split("free");

            if (split.length !== 2){
                throw new Error('Unrecognized price para string');
            }

            priceParaCmpl = [split[0], <span>free</span>, split[1]];
        }

        className = style.container + " " + style.hasPrice;
    }else if (moreInfo){
        className = style.container + " " + style.hasMore;
    }else{
        className = style.container;
    }

    if (moduleName){
        className = className + " " + style.mImg;
    }

    let LHImgPos;
    if (pageInd && image){
        LHImgPos = !Utils.isEven(pageInd);
        if (LHImgPos){
            className = className + " " + style.LHImg;
        }else{
            className = className + " " + style.RHImg;
        }
    }else{
        LHImgPos = true;
    }
    
    return (
        <Section margin="none" className={className}>
                <h2 className={style.secTitle}>{title}</h2>
                {moduleName && 
                    <ModuleIcon name={moduleName} containerClass={style.ModuleIconWrap}></ModuleIcon>
                }
                {image && LHImgPos &&
                    <div className={style.img} style={{backgroundImage: "url(" + image + ")"}} />
                }
                <div className={style.innerCont}>
                    <h2 className={style.title}>{title}</h2>
                    {children}
                    {priceParaCmpl && 
                        <div className={style.price}> 
                            <p>
                                {priceParaCmpl[0]}{priceParaCmpl[1]}{priceParaCmpl[2]}
                            </p>
                        </div>
                    }
                    {moreInfo &&
                        <IndyLink adr={moreInfo} className={style.moreInfo}>
                            <p>
                                <strong>Find out more</strong> <i className="fa fa-arrow-right"></i>
                            </p>
                        </IndyLink>
                    }
                </div>
                {image && !LHImgPos &&
                    <div className={style.img} style={{backgroundImage: "url(" + image + ")"}} />
                }
        </Section>
    );
}